import { mapGetters } from 'vuex'
import _isEqual from 'lodash/isEqual'
import _get from 'lodash/get'

export var pricesArrayMixin = {
  data() {
    return {
      original: null,
    }
  },
  props: {
    prices: {
      type: Array,
      default: () => [],
    },
    mods: {
      type: Array,
      default: null,
    },
    divider: {
      type: String,
      default: ' / ',
    },
    placeholder: {
      type: String,
      default: '$',
    },
    defaultNewPrice: {
      type: [Number, String],
      default: '',
    },
  },
  created() {
    this.original = this.prices
  },
  computed: {
    ...mapGetters('auth', ['loggedIn']),
    isDirty() {
      return this.mods ? !_isEqual(this.original, this.mods) : false
    }, // isDirty
  },
  methods: {
    async addPrice(index) {
      await this.$emit('update', [...this.prices, this.defaultNewPrice])
      await this.focusPrice(index + 1)
    }, // addPrice

    updatePrices(index, price) {
      // Make sure to pass a copy of the
      // local data so the reference isn't passed
      let newPrices = [...this.prices]
      newPrices[index] = price
      this.$emit('update', newPrices)
    }, // updatePrices

    removePrice(index) {
      // Make sure to pass a copy of the
      // local data so the reference isn't passed
      let splicedPrices = [...this.prices]
      splicedPrices.splice(index, 1)
      this.$emit('update', splicedPrices)
    }, // removePrice

    async removePriceAndFocus(index) {
      await this.removePrice(index)
      // Only try to focus if there
      // are more prices left to mess with
      if (this.prices.length >= 1) {
        this.focusPrice(index - 1)
      }
    }, // removePriceAndFocus

    focusPrice(index) {
      if (this.prices.length === 1) {
        index = 0
      }
      let priceComponent = this.$refs[`price-${index}`][0]
      let editable = priceComponent.$refs['editable']
      editable.editor.focus()
      let lastCharIndex = editable.mods.length + 1
      editable.editor.setSelection(lastCharIndex, lastCharIndex)
    }, // focusPrice

    //
    // Event Handlers
    //

    onKeydownGeneric(index, e) {
      if (e.key === this.divider.trim()) {
        e.preventDefault()
        this.onKeyupEnter(index, e)
      }
    }, // onKeydownGeneric

    async onKeyupEnter(index, e) {
      let price = (await this.prices[index]) + ''
      if (!price.length) {
        e.preventDefault()
        return false
      }
      e.preventDefault()
      await this.addPrice(index)
    }, // onKeyupEnter

    onKeydownDelete(index, e) {
      let price = this.prices[index]
      if (typeof price !== 'number' && !price) {
        e.preventDefault()
        this.removePriceAndFocus(index)
      }
    }, // onKeydownDelete

    onBlur(index, event) {
      // just return if getting fired from
      // the delete of the final item
      if (!this.prices.length) {
        return false
      }

      let stringContent = _get(this.prices, `[${index}]`, '') + ''
      let isEmptyString = !stringContent.trim().length
      // Consider the following:
      //        (e.g. "2.5 / | " <-- onDelete empty price )
      // when delete happens, price is removed and the following is true:
      //    > index === 1 (and index + 1 === 2)
      //    > this.prices.length === 1
      //    > isEmptyString === true
      // "blurredFromDelete" is true if the index+1 is greater than the actual
      // number of prices remaining. This indicates that we have blurred
      // from an entity that is no longer available
      let blurredFromDelete = index + 1 > this.prices.length

      // If this is a blur from a NON-deleted price and the price is empty
      if (!blurredFromDelete && isEmptyString) {
        this.removePrice(index)
      }
    }, // onBlur
  }, // methods
  watch: {
    prices(newPrices, oldPrices) {
      if (JSON.stringify(newPrices) === JSON.stringify(oldPrices)) {
        this.original = newPrices
      }
    },
  },
}

export default pricesArrayMixin
