import { UPDATE_ENTITY } from '@constants/mutations'
const GetsDirtyMixin = (entityName = undefined) => ({
  data() {
    return {
      dirtScore: 0,
      entityName,
    }
  },
  methods: {
    adjustDirtScore(adjustment) {
      let origScore = this.dirtScore
      let adjustmentVal = adjustment ? 1 : -1
      this.dirtScore += adjustmentVal
      this.$emit('adjusted dirtScore', {
        origScore,
        adjustmentVal,
        newScore: this.dirtScore,
      })
    }, // adjustDirtScore
    resetDirtScore() {
      this.dirtScore = 0
    }, // resetDirtScore
  },
  watch: {
    isDirty(val, oldval) {
      this.$emit('dirtyUpdate', val)
      let updateMutation = this.entityName
        ? `${this.entityName}/${UPDATE_ENTITY}`
        : undefined
      if (Object.keys(this.$store._mutations).includes(updateMutation)) {
        this.$store.commit(
          updateMutation,
          { id: this.id, key: 'isDirty', val },
          { root: true }
        )
      }
    },
  },
  computed: {
    isDirty() {
      return this.dirtScore > 0
    },
  },
})

export default GetsDirtyMixin
