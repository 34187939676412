<template>
  <VFlex class="item-addon--prices">
    <VLayout row>
      <VFlex
        v-if="!prices.length && loggedIn"
        class="price-placeholder"
        @click.stop="addPrice"
        >{{ placeholder }}</VFlex
      >
      <VFlex v-else-if="prices.length && prices[0]" shrink>+ </VFlex>
      <VFlex v-for="(price, i) of prices" :key="`price-${i}`">
        <MenuAddonPrice
          :key="`price-${i}`"
          :ref="`price-${i}`"
          :price="price === null ? '' : `${price}`"
          :class="[`item-price-${i}`]"
          :placeholder="placeholder"
          @update="updatePrices(i, $event)"
          @keydown.native="onKeydownGeneric(i, $event)"
          @keydown.enter.native="onKeyupEnter(i, $event)"
          @keydown.delete.native="onKeydownDelete(i, $event)"
          @onBlur="onBlur(i, $event)"
          @dirtyUpdate="adjustDirtScore"
        />
        <span
          v-if="prices.length > 1 && i < prices.length - 1"
          :key="`divider-${i}`"
          class="divider"
          >{{ divider }}</span
        >
      </VFlex>
    </VLayout>
  </VFlex>
</template>

<script>
import PricesArray from '@mixins/prices-array'
import MenuAddonPrice from '@menu/MenuAddonPrice'
import GetsDirty from '@mixins/gets-dirty'

export default {
  name: 'MenuAddonPrices',
  components: { MenuAddonPrice },
  mixins: [GetsDirty('ADDONS_PRICES'), PricesArray],
  props: {
    placeholder: {
      type: String,
      default: '$$',
    },
    defaultNewPrice: {
      type: [Number, String],
      default: '',
    },
  },
  data() {
    return {}
  },
}
</script>
<style lang="scss">
.item-addon--prices {
  .price-placeholder {
    font-size: 0.8rem;
    font-style: italic;
    line-height: 1.4em;
    color: darken(white, 25%);
  }
  .divider {
    flex: 0 1 auto;
    margin-right: 3px;
  }
}
.menu-layout .item-addon--prices.is-dirty {
  &::after {
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 3px;
  }
}
</style>
