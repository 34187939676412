import selectElementText from '@utils/select-element-text'
import _get from 'lodash/get'

export var selectedOnMountMixin = (newnessPath) => ({
  data() {
    return {}
  },
  computed: {
    isNew() {
      return _get(this, newnessPath) || false
    }, // isNew
  },
  mounted() {
    if (this.isNew) {
      selectElementText(this.$refs['selectedOnMountTarget'].$el)
    }
  }, // mounted
})

export default selectedOnMountMixin
