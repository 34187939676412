import _isEqual from 'lodash/isEqual'
import _reduce from 'lodash/reduce'
import _get from 'lodash/get'

const deepCompareMods = function(orig, updated) {
  if (_isEqual(orig, updated)) return {}
  return _reduce(
    updated,
    (result, v, k) =>
      // add the key and val to our result
      // if the original key doesn't exist or the updated val is different
      !_get(orig, k) || !_isEqual(v, _get(orig, k))
        ? { ...result, [k]: _get(updated, k) }
        : result,
    []
  )
}

export default deepCompareMods
